import axios from 'axios';
import authenticationService from './AuthenticationService';
import config from './config';
import { isbot } from "isbot";

const client = axios.create({
  baseURL: config.get('SEARCH_API_URL')
});

export default {
  createAnalyticEvent: async analyticEvent => {
    try {
      if (isbot(navigator.userAgent)) {
        return;
      }
      
      const payload = {
        ...analyticEvent,
        metadata: {
          ...analyticEvent.metadata,
          referrer: document.referrer,
          viewport: {
            width: window.innerWidth,
            height: window.innerHeight
          }
        }
      };
      await client.put('/analytic', payload, await authenticationService.getRequestOptions(null, true));
    }
    catch (ex) {
      console.warn('failed to record analytic events', ex, analyticEvent);
    }
  }
}