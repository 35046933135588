import React, { useEffect, useRef, useState, useCallback } from "react";
import styled from "styled-components";
import ListingCard from "../Search/ListingCard";
import { Icon, Button, Loader } from "semantic-ui-react";
import Link from "next/link";
import layout from "../../services/layout";
import SectionHeadingLink from "./SectionHeadingLink";
import SaveSearch from "../Search/SaveSearch";
import AnalyticEventService from "../../services/AnalyticEventService";
import InventoryService from "../../services/InventoryService";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import _ from "lodash";

const ListingCarouselContainer = styled.div`
  position: relative;
  a {
    color: white !important;
  }

  &.limited-width {
    max-width: 1000px;
    @media (min-width: 1000px) {
      margin: 0 auto;
    }
  }

  .swiper-wrapper {
    position: relative;
  }

  &.has-pagination {
    .swiper-wrapper {
      margin-bottom: 40px;
    }
  }

  .swiper-initialized {
    .swiper-pagination-bullet {
      background: white;
    }
  }
`;

const NavigationContainer = styled.div`
  &&&& .button {
    border-radius: 50px !important;
    width: 40px;
    height: 40px;
    background: #2e2e2e !important;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5) inset !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    .icon {
      width: initial;
      font-size: 28px;
      width: 40px;
      height: 40px;
      line-height: 40px;
    }

    &:first-child {
      left: -9px;
    }

    &:last-child {
      right: -9px;
      .icon {
        margin-left: 1px !important;
      }
    }

    @media (max-width: ${layout.global.mobileMax}) {
      display: none;
    }
  }

  &.ad-count-1 {
    display: none;
  }
  &.ad-count-2 {
    display: none;
    @media (max-width: ${layout.global.smallMax}) {
      display: flex;
    }
  }
  &.ad-count-3 {
    display: none;
    @media (max-width: ${layout.global.mediumMax}) {
      display: flex;
    }
  }
  &.ad-count-4 {
    display: none;
    @media (max-width: ${layout.global.largeMax}) {
      display: flex;
    }
  }
`;

const ActionRow = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;

  > * {
    margin-right: 0 !important;
  }

  padding-right: 15px;
  @media (max-width: ${layout.global.mediumMax}) {
    padding-right: 0;
  }

  .ui.basic.inverted.button {
    box-shadow: 0 0 0 ${layout.buttons.outlineThickness}
      ${layout.buttons.inactiveOutline} inset !important;
  }
`;

const cache = {};
function getState(key, queryUrl, serverAds) {
  const now = new Date().valueOf();
  let state = cache[key];
  if (!state || state.expiresAt < now || state.queryUrl !== queryUrl) {
    state = {
      ads: serverAds,
      prefixedAds: [],
      initialSlide: 0,
      expiresAt: now + 3600000, // 1 hour
      queryUrl,
    };
    if (key) {
      cache[key] = state;
    }
  }
  return state;
}

function ListingCarousel({
  title,
  titleSmall,
  serverAds,
  ctaUrl,
  subscribeUrl,
  queryUrl,
  sortBy,
  loadMoreSortBy,
  placement,
  adPlacement,
  loadMoreAdPlacement,
  showActions,
  isFixedSize,
  isHiddenIfEmpty,
  autoplay,
  pagination,
  loop,
}) {
  const state = getState(placement, queryUrl, serverAds);
  const [isLoadingMore, setIsLoadingMore] = useState(!loop);
  const [swiperState, setSwiperState] = useState({});
  const swiperRef = useRef(null);
  const loadingFromOffset = useRef(null);

  const handlePrev = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!swiperRef.current) return;
    swiperRef.current.swiper.slideNext();
  }, []);

  const handleLoadMore = async (sortBy, adPlacement) => {
    const nextOffset = state.ads.length - (state.prefixedAds?.length || 0);
    if (loadingFromOffset.current && nextOffset <= loadingFromOffset.current) {
      return;
    }

    loadingFromOffset.current = nextOffset;
    const loadedAds = (
      await InventoryService.getCarouselAds(queryUrl, nextOffset, sortBy)
    ).map((ad) => ({ ...ad, placement: adPlacement }));
    if (loadedAds.length > 0) {
      AnalyticEventService.createAnalyticEvent({
        eventType: "AD_IMPRESSIONS",
        ads: loadedAds.map((ad, index) => ({
          id: ad.id,
          placement: ad.placement,
          position: state.ads.length + index + 1,
        })),
        metadata: {
          source: placement,
        },
      });
      state.ads = _.uniqBy([...state.ads, ...loadedAds], "id");
    }
    loadingFromOffset.current = null;
    return loadedAds;
  };

  useEffect(() => {
    (async () => {
      if (queryUrl && state.ads.length === 0) {
        setIsLoadingMore(true);
        const loadedAds = await handleLoadMore(sortBy, adPlacement);
        if (loadMoreSortBy) {
          state.prefixedAds = loadedAds;
        }
        await loadMoreIfNearEnd(state.initialSlide);
      }
      setIsLoadingMore(false);
    })();
  }, [queryUrl]);

  useEffect(() => {
    updateSwiperState();
  }, [isLoadingMore]);

  const loadMoreIfNearEnd = async (activeIndex) => {
    const totalAds = state.ads.length;
    const isNearToEnd = activeIndex > totalAds - 6;
    if (queryUrl && isNearToEnd) {
      setIsLoadingMore(true);
      await handleLoadMore(
        loadMoreSortBy || sortBy,
        loadMoreAdPlacement || adPlacement
      );
      setIsLoadingMore(false);
    }
  };

  const updateSwiperState = () => {
    if (!swiperRef.current?.swiper) return;
    setSwiperState({
      atBeginning: swiperRef.current.swiper.isBeginning,
      atEnd: swiperRef.current.swiper.isEnd,
    });
  };

  const fixedSizeSlideStyle = isFixedSize && {
    width: "330px",
    height: "350px",
  };
  return state.ads.length === 0 && isHiddenIfEmpty && !isLoadingMore ? (
    <div></div>
  ) : (
    <ListingCarouselContainer
      className={
        (state.ads.length > 0 && state.ads.length <= 2
          ? " limited-width"
          : "") + (pagination ? " has-pagination" : "")
      }
    >
      {title && (
        <SectionHeadingLink
          href={ctaUrl ? ctaUrl + "?source=" + placement + "_TITLE" : false}
          hideSeeAll={showActions}
        >
          {title}
        </SectionHeadingLink>
      )}
      {titleSmall && <h4>{titleSmall}</h4>}
      <Swiper
        ref={swiperRef}
        slidesPerView={isFixedSize ? "auto" : state.ads.length > 1 ? 1.1 : 1}
        initialSlide={state.initialSlide}
        breakpoints={
          !isFixedSize &&
          state.ads.length > 1 && {
            480: {
              slidesPerView: Math.min(serverAds?.length, 1.1),
            },
            768: {
              slidesPerView: Math.min(serverAds?.length, 2.1),
            },
            1024: {
              slidesPerView: Math.min(serverAds?.length, 3.1),
            },
            1400: {
              slidesPerView: Math.min(serverAds?.length, 4.1),
            },
          }
        }
        loop={loop}
        autoplay={
          autoplay && {
            delay: 2000,
            disableOnInteraction: true,
          }
        }
        pagination={
          pagination && {
            clickable: true,
            dynamicBullets: true,
          }
        }
        modules={[
          autoplay && Autoplay,
          pagination && Pagination,
          Navigation,
        ].filter((x) => x)}
        onSlideChange={async (swiper) => {
          state.initialSlide = swiper.activeIndex;
          await loadMoreIfNearEnd(swiper.activeIndex);
          updateSwiperState();
        }}
        onResize={updateSwiperState}
        onAfterInit={updateSwiperState}
        onReachBeginning={updateSwiperState}
        onReachEnd={updateSwiperState}
      >
        {state.ads.map((ad) => (
          <SwiperSlide
            key={ad.id + (ad.placement || placement)}
            style={fixedSizeSlideStyle}
          >
            <ListingCard
              {...ad}
              isStandOut={false}
              isHighlighted={false}
              alwaysGrid={serverAds?.length !== 1 || isFixedSize}
              layoutClass={
                (serverAds?.length === 1 && !isFixedSize
                  ? "list-view"
                  : "grid-view") +
                (state.ads.length > 1 || queryUrl ? " in-carousel" : "") +
                (isFixedSize ? " is-fixed-size" : "")
              }
              primaryImageOnly={true}
              placement={ad.placement || placement}
            />
          </SwiperSlide>
        ))}
        {isLoadingMore && (
          <SwiperSlide style={fixedSizeSlideStyle}>
            <Loader active />
          </SwiperSlide>
        )}
      </Swiper>
      <NavigationContainer className={`ad-count-${state.ads.length}`}>
        <Button
          icon
          basic
          inverted
          onClick={handlePrev}
          disabled={!loop && swiperState.atBeginning}
        >
          <Icon name="angle left" />
        </Button>
        <Button
          icon
          basic
          inverted
          onClick={handleNext}
          disabled={!loop && swiperState.atEnd}
        >
          <Icon name="angle right" />
        </Button>
      </NavigationContainer>
      {showActions && ctaUrl && (
        <ActionRow>
          {subscribeUrl && (
            <SaveSearch
              canonicalUrl={subscribeUrl}
              searchName={title}
              source={placement + "_ACTION"}
            />
          )}
          {ctaUrl && (
            <Link href={ctaUrl + "?source=" + placement + "_ACTION"}>
              <Button basic inverted>
                See all <Icon name="chevron right" />
              </Button>
            </Link>
          )}
        </ActionRow>
      )}
    </ListingCarouselContainer>
  );
}

export default ListingCarousel;
